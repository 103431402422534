<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import formExt from './components/formExt.vue'; // 控件类型

formCreate.component('formExt', formExt);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
      dateRangeList: [],
      formExtDataSelect: '',
    };
  },
  async created() {
    const rule = await this.getFormRule('interface_parameters_form');
    this.reload(rule);
    if (this.formConfig.id) {
      request.post('/kms/kmsinterfaceparam/query', { id: this.formConfig.id }).then((res) => {
        this.row = res.result;
        const formExtData = this.getRule('formExt');
        // 判断日期选择范围是否显示
        if (res.result.fieldClassify === 'DATERANGE' || res.result.fieldClassify === 'DATE') {
          this.hiddenFields(false, ['dateRange']);
          this.getDateChange(res.result.fieldClassify);
          // 组装日期范围数据
          const arr = [];
          res.result.options.map((v) => {
            const item = v;
            arr.push(item.optionValue);
            return item;
          });
          this.setValue({
            dateRange: arr,
          });
          // 组装开始、结束日期数据
          res.result.subOptions.map((v) => {
            const item = v;
            item.nameLabel = item.dateFlagClassify === 'START' ? '开始时间参数名称' : '结束时间参数名称';
            item.valueLabel = item.dateFlagClassify === 'END' ? '结束时间参数属性' : '开始时间参数属性';
            return item;
          });
          formExtData.value = res.result.subOptions;
        } else {
          this.hiddenFields(true, ['dateRange']);
        }
        // 组件赋值
        if (res.result.fieldClassify === 'SELECT' || res.result.fieldClassify === 'CHECKBOX') {
          formExtData.value = res.result.options;
        }
        const formExtDataEdit = this.getRule('formExt');
        formExtDataEdit.props = {
          ...formExtDataEdit.props,
          fieldClassify: res.result.fieldClassify,
        };
        this.formExtDataSelect = res.result.fieldClassify;
        this.dateRangeList = res.result.options;
        this.setValue({
          ...res.result,
        });
      });
    }
  },

  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'orderType') {
        // 接口参数
        item.restful = '/kms/kmsAdmin/tenantry/direct/param/orderType/list';
        item.restfulParamsGetValue = { directId: 'directId' };
        item.optionsKey = { label: 'orderTypeName', value: 'orderType' };
      } else if (item.field === 'directId') {
        // 商超系统
        item.restful = '/kms/kmsdirectsystem/list';
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'directName',
        };
        item.optionsKey = { label: 'directName', value: 'id' };
      }
      return item;
    },
    formComplete() {
      const fieldClassify = this.getRule('fieldClassify');
      const formExtData = this.getRule('formExt');
      fieldClassify.on.change = (e) => {
        this.hiddenFields(true, ['dateRange']);
        this.formExtDataSelect = e;
        formExtData.props = {
          ...formExtData.props,
          fieldClassify: e,
        };
        if (e === 'SELECT' || e === 'CHECKBOX') {
          // 下拉单选或多选
          formExtData.value = [{
            optionName: '',
            optionValue: '',
          }];
        } else if (e === 'DATERANGE' || e === 'DATE') {
          // 日期选择
          if (e === 'DATERANGE') {
            formExtData.value = [{
              optionName: '',
              optionValue: '',
              nameLabel: '开始时间参数名称',
              valueLabel: '开始时间参数属性',
              dateFlagClassify: 'START',
            }, {
              optionName: '',
              optionValue: '',
              nameLabel: '结束时间参数名称',
              valueLabel: '结束时间参数属性',
              dateFlagClassify: 'END',
            }];
          } else {
            formExtData.value = [{
              optionName: '',
              optionValue: '',
              nameLabel: '开始时间参数名称',
              valueLabel: '开始时间参数属性',
              dateFlagClassify: 'START',
            }];
          }
          // 日期选择范围
          this.hiddenFields(false, ['dateRange']);
          this.getDateChange(e);
        }
      };
    },
    getDateChange(param) {
      const dateRange = this.getRule('dateRange');
      dateRange.restful = '/mdm/mdmdictdata/list';
      dateRange.restfulParams = {
        dictTypeCode: (param === 'DATERANGE') ? 'kms_param_date_range' : 'kms_param_date_single',
      };
      dateRange.optionsKey = { label: 'dictValue', value: 'dictCode' };
      dateRange.on.change = (result) => {
        const { options } = dateRange;
        const arr = [];
        result.map((v) => {
          const item = v;
          const index = options.findIndex((val) => val.dictCode === v);
          if (index > -1) {
            arr.push({
              optionName: options[index].dictValue,
              optionValue: options[index].dictCode,
            });
          }
          return item;
        });
        this.dateRangeList = arr;
      };
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      const param = {
        ...this.row,
        ...formData,
        options: (this.formExtDataSelect === 'DATERANGE' || this.formExtDataSelect === 'DATE') ? this.dateRangeList : formData.formExt,
        subOptions: formData.formExt,
      };
      request.post(this.formConfig.id ? ('/kms/kmsinterfaceparam/update') : ('/kms/kmsinterfaceparam/save'), param).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });

          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
