var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.fieldClassify === "SELECT" || _vm.fieldClassify === "CHECKBOX"
        ? _c(
            "el-form",
            { attrs: { "label-width": "125px" } },
            [
              _c(
                "el-row",
                { attrs: { type: "flex" } },
                [
                  _vm._l(_vm.value, function (item, index) {
                    return [
                      _c(
                        "el-col",
                        { key: index, attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "选项名称" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "选项名称" },
                                model: {
                                  value: item.optionName,
                                  callback: function ($$v) {
                                    _vm.$set(item, "optionName", $$v)
                                  },
                                  expression: "item.optionName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { key: index + "1", attrs: { span: 24 } },
                        [
                          _c("el-form-item", { attrs: { label: "选项属性" } }, [
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "选项属性" },
                                  model: {
                                    value: item.optionValue,
                                    callback: function ($$v) {
                                      _vm.$set(item, "optionValue", $$v)
                                    },
                                    expression: "item.optionValue",
                                  },
                                }),
                                _c("i", {
                                  staticClass: "el-icon-remove-outline",
                                  on: {
                                    click: function ($event) {
                                      return _vm.del(index)
                                    },
                                  },
                                }),
                                _c("i", {
                                  staticClass: "el-icon-circle-plus-outline",
                                  on: { click: _vm.add },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.fieldClassify === "DATERANGE" || _vm.fieldClassify === "DATE"
        ? _c(
            "el-form",
            { attrs: { "label-width": "125px" } },
            [
              _c(
                "el-row",
                { attrs: { type: "flex" } },
                [
                  _vm._l(_vm.value, function (item, index) {
                    return [
                      _c(
                        "el-col",
                        { key: index, attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: item.nameLabel } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: item.nameLabel },
                                model: {
                                  value: item.optionName,
                                  callback: function ($$v) {
                                    _vm.$set(item, "optionName", $$v)
                                  },
                                  expression: "item.optionName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { key: index + "1", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: item.valueLabel } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: item.valueLabel },
                                model: {
                                  value: item.optionValue,
                                  callback: function ($$v) {
                                    _vm.$set(item, "optionValue", $$v)
                                  },
                                  expression: "item.optionValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }