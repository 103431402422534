<template>
  <div>
    <!-- 下拉单选或者多选 -->
    <el-form label-width="125px" v-if="fieldClassify === 'SELECT' || fieldClassify === 'CHECKBOX'">
      <el-row type="flex">
        <template v-for="(item, index) in value">
          <el-col :span="24" :key="index">
            <el-form-item  label="选项名称">
              <el-input placeholder="选项名称" v-model="item.optionName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :key="index + '1'">
            <el-form-item  label="选项属性">
              <div class="flex">
                <el-input placeholder="选项属性" v-model="item.optionValue"></el-input>
                <i class="el-icon-remove-outline" @click="del(index)"></i>
                <i class="el-icon-circle-plus-outline" @click="add"></i>
              </div>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
    <!-- 日期选择 -->
    <el-form label-width="125px" v-if="fieldClassify === 'DATERANGE' || fieldClassify === 'DATE'">
      <el-row type="flex">
        <template v-for="(item, index) in value">
          <el-col :span="24" :key="index">
            <el-form-item :label="item.nameLabel">
              <el-input :placeholder="item.nameLabel" v-model="item.optionName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :key="index + '1'">
            <el-form-item :label="item.valueLabel">
              <el-input :placeholder="item.valueLabel" v-model="item.optionValue"></el-input>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    value: Array,
    fieldClassify: String,
  },
  data() {
    return {
      dateOptions: [],
      dateRange: [],
    };
  },
  watch: {
    value(val) {
      console.log('value', val);
    },
  },
  created() {
    this.$emit('getFormExtData', this.value);
  },
  methods: {
    // 添加下拉多选数据
    add() {
      this.value.push({
        optionName: '',
        optionValue: '',
      });
      this.$emit('getFormExtData', this.value);
    },
    // 删除下拉多选数据
    del(index) {
      this.value.splice(index, 1);
      this.$emit('getFormExtData', this.value);
    },
  },
};
</script>
<style lang="less" scoped>
  .flex{
    display: flex;
    align-items: center;
  }
  .el-icon-circle-plus-outline, .el-icon-remove-outline{
    font-size: 26px;
    color: #53a8ff;
    margin-left: 10px;
    cursor: pointer;
  }
</style>
