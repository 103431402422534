<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from '../form';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  props: {
    treeCode: String,
  },
  data() {
    return {
      params: {
        directId: '',
      },
    };
  },
  watch: {
    async treeCode(val) {
      if (!val) return false;
      await request.post('/kms/kmsAdmin/tenantry/direct/param/orderType/list', { directId: val }).then((res) => {
        res.result.map((a) => {
          const child = a;
          child.label = child.orderTypeName;
          child.value = child.orderType;
          return child;
        });
        this.searchList.map((v, k) => {
          const item = v;
          if (item.field === 'orderType' && val) {
            this.$set(this.searchList[k].render, 'options', res.result);
            this.$forceUpdate();
          }
          return item;
        });
      });
      this.params.directId = val;
      this.getList();
    },
  },
  async created() {
    await this.getConfigList('interface_parameters_list');
  },
  methods: {
    modalClick({ val, row }) {
      this.formName = 'Form';
      if (val.code === 'add') {
        // 新增
        this.formConfig = {
          type: val.code,
        };
        this.modalConfig.title = '新增';
        this.modalConfig.width = '50%';
        this.openModal();
      } else if (val.code === 'edit') {
        // 编辑
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '编辑';
        this.modalConfig.width = '50%';
        this.openModal();
      }
    },
  },
};
</script>
